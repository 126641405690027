import React, { useCallback, useState, ChangeEvent } from "react";
import { Text, Flex, Box, Button, Input } from "@11fsfoundry/figloo";
import {
  useListExternalUserFactsLazyQuery,
  useSetUserFactsMutation,
  ListExternalUserFactsDocument,
} from "../types-and-hooks";

function Users() {
  const [externalUserId, setExternalUserId] = useState("");
  const [factName, setFactName] = useState("");
  const [factValue, setFactValue] = useState("");
  const handleError = useCallback((err) => {
    window.alert(err);
  }, []);
  const [
    listExternaUserFacts,
    { data, loading, client },
  ] = useListExternalUserFactsLazyQuery({
    onError: handleError,
  });
  const handleUpdate = useCallback(() => {
    // hacky, hopefully in the future the mutation will return the facts
    const facts = data?.externalUser.facts
      .filter((fact) => {
        // from this tool we only modify facts with source "demo_tools"
        if (fact.source !== "demo_tools") {
          return true;
        }
        return fact.factName !== factName;
      })
      .concat({
        factName,
        factValue,
        source: "demo_tools",
        createdAt: new Date().toISOString(),
      });
    client?.writeQuery({
      query: ListExternalUserFactsDocument,
      variables: {
        externalUserId,
      },
      data: {
        externalUser: {
          ...data?.externalUser,
          facts,
        },
      },
    });
    setFactName("");
    setFactValue("");
  }, [client, data, externalUserId, factName, factValue]);
  const [
    setUserFacts,
    { loading: loadingSetUserFacts },
  ] = useSetUserFactsMutation({
    onError: handleError,
    onCompleted: handleUpdate,
  });
  return (
    <Flex flexDirection="column" flex={1}>
      <Text mb={1}>External User Id</Text>
      <Flex maxWidth={600} flexDirection="row" mb={3}>
        <Input
          placeholder="i.e. foundry:identity:user:auth0:email|5e6b5f4d76c8536728529454"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const id = e.target.value;
            setExternalUserId(id);
          }}
          value={externalUserId}
        />
        <Button
          loading={loading}
          onClick={() =>
            listExternaUserFacts({
              variables: {
                externalUserId,
              },
            })
          }
          variant="primary"
        >
          Load
        </Button>
      </Flex>
      {data?.externalUser && (
        <Box mb={3}>
          <Text fontSize={2} mb={3}>
            User facts
          </Text>
          {data?.externalUser.facts.map((fact) => (
            <Box key={`${fact.source}_${fact.factName}`} mb={2}>
              <Flex flexDirection="row">
                <Text mr={1}>
                  {fact.factName}: {fact.factValue}
                </Text>
                <Text fontSize={0} color="copyThree">
                  ({fact.source})
                </Text>
              </Flex>
            </Box>
          ))}
        </Box>
      )}
      {data?.externalUser && (
        <Box
          maxWidth={300}
          p={2}
          sx={{ borderColor: "gsRat", borderWidth: 1, borderStyle: "solid" }}
        >
          <Text fontSize={2} mb={2}>
            Add new user fact
          </Text>
          <Text mb={1}>Fact name</Text>
          <Input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const id = e.target.value;
              setFactName(id);
            }}
            value={factName}
            mb={2}
          />
          <Text mb={1}>Fact value</Text>
          <Input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const id = e.target.value;
              setFactValue(id);
            }}
            value={factValue}
            mb={2}
          />
          <Button
            loading={loadingSetUserFacts}
            onClick={() =>
              setUserFacts({
                variables: {
                  externalUserId,
                  facts: [
                    {
                      factName,
                      factValue,
                      source: "demo_tools",
                    },
                  ],
                },
              })
            }
          >
            Add fact
          </Button>
        </Box>
      )}
    </Flex>
  );
}

export default Users;
