import React, { ReactElement, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import introspectionQueryResultData from "./fragmentTypes.json";
import { ThemeProvider } from "emotion-theming";
import { theme, Box } from "@11fsfoundry/figloo";
import {
  ApolloProvider,
  InMemoryCache,
  ApolloClient,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import NavHeader from "./components/NavHeader";
import ProductApplicationScreen from "./screens/ProductApplicationScreen";
import ProductApplicationsScreen from "./screens/ProductApplicationsScreen";
import LoginScreen from "./screens/LoginScreen";
import UsersScreen from "./screens/UsersScreen";
import { Auth0Provider, useAuth0 }  from "@auth0/auth0-react";

const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData.possibleTypes,
});

let GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_SERVER

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

function Routes() {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <Route path="/" component={LoginScreen} />;
  }

  return (
    <Box height="100%">
      <NavHeader />
      <Box bg="#fafafa" p={4}>
      <Route exact path="/">
        <Redirect to="/product-applications" />
      </Route>
        <Route
          exact
          path="/product-applications"
          component={ProductApplicationsScreen}
        />
        <Route
          exact
          path="/product-applications/:id"
          component={ProductApplicationScreen}
        />
        <Route exact path="/users" component={UsersScreen} />
      </Box>
    </Box>
  );
}

const GraphQLProvider = ({ children }: { children: ReactElement }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently()
        setToken(token)
      } catch (exception) {
        // Expected before sign-in
      }
    }
    getToken()
    
    return setToken("")
  }, [getAccessTokenSilently])

  const authLink = setContext((_, { headers }) => {
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    }
    return {
      headers,
    };
  });
  const apolloClient = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
  });
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

function App() {
  return (
    <Router>
      <Auth0Provider 
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string} 
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string} 
        audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
        redirectUri={window.location.origin}>
        <GraphQLProvider>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </GraphQLProvider>
      </Auth0Provider>
    </Router>
  );
}

export default App;
