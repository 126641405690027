import React, { useState, ChangeEvent, useCallback } from "react";
import { Text, Flex, Box, Input, Button, Select } from "@11fsfoundry/figloo";
import { useHistory } from "react-router-dom";
import { useListUserProductApplicationsLazyQuery } from "../types-and-hooks";

function ProductApplications() {
  const history = useHistory();
  const [productApplicationId, setProductApplicationId] = useState("");
  const [externalUserId, setExternalUserId] = useState("");
  const handleError = useCallback((err) => window.alert(err.message), []);
  const [
    listUserProductApplications,
    { data, loading },
  ] = useListUserProductApplicationsLazyQuery({
    fetchPolicy: "network-only",
    onError: handleError,
  });
  return (
    <Flex flexDirection="column" flex={1}>
      {/* Temporary guide to setting 11Money facts */}
      <Box backgroundColor="slate">
      <Text fontSize={2} mb={1}>11fs-money workflow facts guide</Text>
        
        <ul>dob: Must be in format "YYYY-MM-DD", e.g. 1988-03-20</ul>

        <ul>address: House number/name</ul>
        <ul>addressLine2: Optional (can be blank), but suggest Street Name</ul>
        <ul>addressLine3-5: Optional (can be blank) for more info (not town/country/postcode)</ul>
        <ul>town, postcode, country: Self-explanatory</ul>
      </Box>
      <Box mb={2}>
        <Text fontSize={2} mb={1}>
          Load all product applications for a specific user
        </Text>
        <Text mb={1}>External User Id</Text>
        <Flex maxWidth={600} flexDirection="row">
          <Input
            placeholder="i.e. foundry:identity:user:auth0:email|5e6b5f4d76c8536728529454"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const id = e.target.value;
              setExternalUserId(id);
            }}
            value={externalUserId}
          />
          <Button
            loading={loading}
            onClick={() =>
              listUserProductApplications({
                variables: {
                  externalUserId,
                },
              })
            }
            variant="primary"
          >
            Load
          </Button>
        </Flex>
      </Box>
      {!loading && data && (
        <Box mb={2}>
          <Text mb={1}>Select product application</Text>
          <Flex maxWidth={600} flexDirection="row">
            <Select
              minWidth={600}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                const id = e.target.value;
                history.push(`/product-applications/${id}`);
              }}
            >
              <option value=""></option>
              {data.externalUser.productApplications.results.map(
                (productApplication) => (
                  <option
                    key={productApplication.id}
                    value={productApplication.id}
                  >
                    {productApplication.product.name}
                  </option>
                )
              )}
            </Select>
          </Flex>
        </Box>
      )}
      <Box mt={4}>
        <Text fontSize={2} mb={1}>
          Load a specific Product Application
        </Text>
        <Text mb={1}>Product Application Id</Text>
        <Flex maxWidth={600} flexDirection="row">
          <Input
            placeholder="i.e. foundry:product:application:foundry:f28b8d43-c397-40f5-badc-d5820ce35b65"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const id = e.target.value;
              setProductApplicationId(id);
            }}
            value={productApplicationId}
          />
          <Button
            loading={loading}
            onClick={() =>
              history.push(`/product-applications/${productApplicationId}`)
            }
            variant="primary"
          >
            Load
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}

export default ProductApplications;
